import React from 'react'
import { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';
import {Link} from "react-router-dom";
import {motion} from 'framer-motion';

function Searched() {

    const [searchedRecipes, setSearchedRecipes] = useState([]);
    let params = useParams();

    const getSearched = async (name) => {
        const data =await fetch (
            `https://api.spoonacular.com/recipes/complexSearch?apiKey=${process.env.REACT_APP_API_KEY}&query=${name}`
            );
        const recipes = await data.json();
        setSearchedRecipes(recipes.results);
    };

    useEffect(() => {
        getSearched(params.search);
    },[params.search]);

  return (
      <motion.div
      animate={{opacity:1}}
      initial={{opacity:0}}
      exit={{opacity:0}}
      transition={{duration:0.5}}>
          
        <Grid>
            {searchedRecipes.map((item) => {
                return(
                    <Card key={item.id}>
                    <Link to={"/recipe/" + item.id}>
                        <img src={item.image} alt="" />
                        <h4>{item.title}</h4>
                    </Link>
                    </Card>
                );
            })}
        </Grid>
    </motion.div>
  )
}

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(20rem, 1fr));
    grid-gap: 1rem;
`;
const Card = styled.div`
    img {
        width:100%;
        border-radius:0.5rem;
    }
    h4{
        text-align:center;
        padding:0.2rem 0 1rem 0;
        text-decoration:none;
    }
    a {
        text-decoration:none; 
    }
`;

export default Searched