import { useEffect, useState } from "react";
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import {motion} from 'framer-motion';

import React from 'react';

function Recipe() {

  const [details, setDetails] = useState({});
  const [activeTab, setActiveTab] = useState("instructions");
  const params = useParams();

const fetchDetails = async () => {
  const resp = await fetch(
    `https://api.spoonacular.com/recipes/${params.id}/information?apiKey=${process.env.REACT_APP_API_KEY}`
  );
  const data = await resp.json();
  return data;
};

useEffect(() => {
  let isMounted = true;

  fetchDetails().then((data) => {
    if (isMounted) setDetails(data);
  });
  return () => {
    isMounted = false;
  };
}, [params.id]);

return (
  <motion.div
  animate={{opacity:1}}
  initial={{opacity:0}}
  exit={{opacity:0}}
  transition={{duration:0.5}}>
  <Wrapper>
    <div>
      <h2>{details.title}</h2>
      <img src={details.image} alt={details.title} />
    </div>
    <Info>
      <Button
        className={activeTab === "ingredients" ? "active" : ""}
        onClick={() => setActiveTab("ingredients")}
      >
        Ingredients
      </Button>
      <Button
        className={activeTab === "instructions" ? "active" : ""}
        onClick={() => setActiveTab("instructions")}
      >
        Instructions
      </Button>
      {activeTab === "ingredients" && (
        <ul>
          {details.extendedIngredients.map(({ id, original }) => (
            <li key={id}>{original}</li>
          ))}
        </ul>
      )}

      {activeTab === "instructions" && (
        <div>
          <p dangerouslySetInnerHTML={{ __html: details.summary }}></p>
          <p dangerouslySetInnerHTML={{ __html: details.instructions }}></p>
        </div>
      )}
    </Info>
  </Wrapper>
  </motion.div>
);
};

const Wrapper = styled.div`
margin: 10rem inherit 5rem;
display: flex;
@media (max-width: 1068px) {
  flex-direction: column;
}
.active {
  background: linear-gradient(35deg, #494949, #313131);
  color: #fff;
}
h2 {
  margin-bottom: 2rem;
  margin: 1rem 0rem 0.5rem 0rem;
  text-align: center;
}
ul {
  margin-top: 1rem;
}
li {
  font-size: 1.0rem;
  line-height: 2.0rem;
}
p {
  margin: 1rem 0;
  font-size: 1.0rem;
  line-height: 1.7rem;
  &:first-child {
    margin-top: 1rem;
  }
}
img{
  border-radius:0.5rem;
  left: 0;
  object-fit: cover;
}   

@media (max-width: 1200px) {
    
}
@media (max-width: 480px) {
  img{
    border-radius:0.5rem;
    left: 0;
    width:100%;
    height:auto;
    object-fit: cover;
  }   
}


`;

const Button = styled.button`
padding: 1rem 2rem;
color: #313131;
background: #fff;
border: 2px solid #000;
margin-right: 1.2rem;
font-weight: 600;

@media (max-width: 1068px) {
  padding: 1rem 2rem;

}
@media (max-width: 360px) {
  padding: 1rem 1.6rem;
}
`;

const Info = styled.div`
margin-left: 3rem;
@media (max-width: 1068px) {
  margin-top: 1rem;
  margin-left: 1rem;
}
`;

export default Recipe;