import { useEffect, useState } from "react";
import styled from "styled-components";
import {Splide, SplideSlide} from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import {Link} from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import {motion} from 'framer-motion';

function Veggie() {

  const[veggie, setVeggie] = useState([]);

  useEffect(() => {
      getVeggie();
  },[]);

const getVeggie = async () => {

  const check = localStorage.getItem('veggie');

  if(check){
      setVeggie(JSON.parse(check));
  }else{
      const api = await fetch (`https://api.spoonacular.com/recipes/random?apiKey=${process.env.REACT_APP_API_KEY}&number=9&tags=vegetarian`);
      const data = await api.json();

      localStorage.setItem("veggie", JSON.stringify(data.recipes));
      console.log(data.recipes);
      setVeggie(data.recipes);
  };


};

  return ( 
    <motion.div
    animate={{opacity:1}}
    initial={{opacity:0}}
    exit={{opacity:0}}
    transition={{duration:0.5}}
    >

            <Wrapper>
            <h3>Veggie Dishes</h3>  
            
            <BrowserView>

                <Splide options={{
                        perPage: 4,
                        arrows:false,
                        pagination: false,
                        drag: "free",
                        gap: "1rem",
                    }}>              

                {veggie.map((recipe) => {
                    return(
                        <SplideSlide key={recipe.id}>
                        <Link to={"/recipe/" + recipe.id}>
                            <Card>
                                <p>{recipe.title}</p>
                                <img src={recipe.image} alt={recipe.title} />
                                <Gradient />
                            </Card> 
                        </Link>
                        </SplideSlide>
                    );
                })}
                </Splide>

            </BrowserView>
            
            <MobileView>

                <Splide options={{
                        perPage: 2,
                        arrows:false,
                        pagination: false,
                        drag: "free",
                        gap: "1rem",
                    }}>              

                {veggie.map((recipe) => {
                    return(
                        <SplideSlide key={recipe.id}>
                        <Link to={"/recipe/" + recipe.id}>
                            <Card>
                                <p>{recipe.title}</p>
                                <img src={recipe.image} alt={recipe.title} />
                                <Gradient />
                            </Card> 
                        </Link>
                        </SplideSlide>
                    );
                })}
                </Splide>
                
            </MobileView>

                <p>Swipe to see more</p>

            </Wrapper>

    </motion.div>
);

}

const Wrapper = styled.div`
    margin: 0.5rem 0rem;
    padding: 0rem 0rem;
    h3{
        font-size:1.3rem;
    }
    p{
        text-align:center;
        margin-top:0.5rem;
        font-weight:500;
        font-size:0.8rem;
    }
`;
const Card = styled.div`
    min-height: 22rem;
    border-radius: 0.5rem;
    overflow:hidden;
    position:relative;
    text-align:center;

    img{
        border-radius:0.5rem;
        position:absolute;
        left: 0;
        width:100%;
        height:100%;
        object-fit: cover;
    }
    p{
        position:absolute;
        z-index:10;
        left:50%;
        bottom:0%;
        transform:translate(-50%, 0%);
        color: #fff;
        width:100%;
        font-weight:600;
        font-size:1rem;
        height:40%;
        display:flex;
        justify-content:center;
        align-items:center;
    }
    @media (max-width: 1200px) {
        min-height: 20rem;
    }
    @media (max-width: 480px) {
        min-height: 12rem;
        p{
            font-size:0.8rem;
            height:50%;
        }
    }
    @media (max-width: 360px) {
        min-height: 9rem;
        p{
            font-size:0.7rem;
            height:55%;
        }
    }
`;

const Gradient = styled.div`
        z-index:3;
        position:absolute;
        width:100%;
        height:100%;
        background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.6));
`

export default Veggie;